.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.8) ,rgba(0, 0, 0, 0.9));
  z-index: 0;
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  text-align: center;
}

h1 {
  font-size: 3em;
  margin: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2em;
  }
}

.animated-text {
  position: absolute;
  top: 0;
  font-size: 1em;
  color: white;
  animation: fall linear;
  pointer-events: none;
  z-index: 2; /* Make sure it appears on top of the overlay */
}

@keyframes fall {
  to {
    transform: translateY(100vh);
  }
}


.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1; /* Ensure it stays above the video overlay */
}

.footer p {
  margin: 0;
}

